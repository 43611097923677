// Modal styles
.modal {
  display: none;
  flex-direction: column;
  width: 50rem;
  max-width: 90%;
  max-height: 90%;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;

  /* Use this for centering if unknown width/height */
  transform: translate(-50%, -50%);
  background: #ffffff;

  // I wrote this because IE is still a thing...
  overflow: hidden;
  -ms-overflow-y: visible;

  &.is-active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.closed {
  display: none;
}

.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.6);
}

.modal-body,
.modal-card-body {
  .inline-title {
    padding: 0.625rem 0rem 0.625rem 0rem;
    button, svg {
      float: right;
      width: 2.813rem;
      height: 2.813rem;
    }
    svg {
      display: inline-block;

      path {
        stroke: #959497;
      }
    }
  }
}

.modal-card-body {
  p {
    margin-bottom: 1.5rem;
  }
  h1 {
    margin-left: 2.813rem;
  }
  .sign-in {
    display: flex;
    justify-content: center;
  }
}

.modal-body {
  .inline-title {
    display: flex;
    justify-content: space-between;
    margin: 0rem -2.188rem 0rem 0rem;
  }

  overflow: auto;
  padding: 2.5rem 3.125rem;
  background-color: $foreground-color;

  h1 {
    font-size: 1.875rem;
    margin-bottom: 1.875rem;
  }

  p {
    margin-bottom: 1.25rem;
  }
}

.modal-nav {
  .inline-padding {
    padding: 0.625rem 0 0 0;
    text-align: right;
    border-top: 1px solid #cbcbcb;
  }
}

.modal {
  display: none;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-content,
.modal-card {
  max-height: calc(100vh - 10rem);
  overflow: auto;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 48.063rem), print {
  .modal-content,
  .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 2.5rem);
    width: 40rem;
  }
}

.modal-close {
  background: none;
  height: 2.5rem;
  position: fixed;
  right: 1.25rem;
  top: 1.25rem;
  width: 2.5rem;
}

.close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100vh - 2.5rem);
  overflow: hidden;
  -ms-overflow-y: visible;
}

.modal-card-head,
.modal-card-foot {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: whitesmoke;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 1.25rem;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #D6D4D4;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #D6D4D4;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: 0.625rem;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: white;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 1.25rem;
  margin-bottom: 1.875rem;
}

.modal-card-foot {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.modal-card {
  padding: .7rem;
}

.modal-open {
  // Used on the html element to remove h=body scrolling while the modal is Open
  overflow: hidden !important;

  .modal-overlay {
    display: flex;
  }
}

@media only screen and (max-width: 62.5em) { /*1000px*/
  .modal-body {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

}

@media only screen and (max-width: 50em) { /*800px*/
  .modal-head h1 {
    font-size: 1.50rem;
  }
}

@media only screen and (max-width: 37.5em) { /*600px*/
  .modal-head h1 {
    font-size: 1.4rem;
  }
  .modal-head {
    padding-left: 2.5rem;
  }
  .modal-body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .modal-nav {

    .nav-padding,
    .inline-padding {
      .button {
        font-size: 1.00rem;
        padding-left: 0.65rem;
        padding-right: 0.65rem;
        padding-top: calc(0.65em - 1px);
        padding-bottom: calc(0.65em - 1px);
      }
    }
  }
}

@media only screen and (max-width: 34.375em) { /*550px*/
  .modal-head h1 {
    font-size: 1.3rem;
  }
  .modal-open {
    .modal-nav {
      .button.is-large {
        font-size: .8rem;
        padding-left: 1.4rem;
        padding-right: 1.4rem;
      }
      .button {
        font-size: 1.00rem;
        padding-left: 0.65rem;
        padding-right: 0.65rem;
        padding-top: calc(0.65em - 1px);
        padding-bottom: calc(0.65em - 1px);
      }
    }

    .modal-head, 
    .modal-body {
      padding: 1.25rem 2.5rem;
    }

    .modal {
      max-width: 92%;
      max-height: 92%;
    }
  }
}

@media only screen and (max-width: 30em) { /* 480px */
  .modal-open {
    .modal-head h1 {
      font-size: 1.2rem;
    }
    .modal-nav {
      .nav-padding, .inline-padding {

        .button {
          font-size: 1.00rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          padding-top: calc(0.5em - 1px);
          padding-bottom: calc(0.5em - 1px);
        }
        .button.is-large {
          font-size: .4rem;
          padding-left: 0.7rem;
          padding-right: 0.7rem;
        }
      }
    }

    .modal-head,
    .modal-body {
      padding: 0.938rem 1.563rem;
    }
  }
  
  .modal,
  .modal-card {
    max-width: 98%;
    max-height: 98%;
  }
  .modal-card-body {
    margin-bottom: .75rem;
    h1 {
        margin-left: 0rem;
    }
  }
}

@media only screen and (max-width: 22.5em) { /* 360px */
  .modal-open {
    .modal-nav {
      .nav-padding,
      .inline-padding {
        .button {
          font-size: 1.0rem;
          padding-left: 0.4rem;
          padding-right: 0.4rem;
          padding-top: calc(0.4em - 1px);
          padding-bottom: calc(0.4em - 1px);
        }
      }
      .modal-head,
      .modal-body {
        padding: 0.625rem 1.25rem;
      }
    }
    .modal,
    .modal-card {
      max-width: 99%;
      max-height: 99%;
    }
  }
}

@media only screen and (max-width: 15em) { /*240px*/
  .modal-open {
    .modal-head h1 {
      font-size: 1.00rem;
    }
    .modal-nav {
      .nav-padding,
      .inline-padding {
        .button {
          width: 0.813rem; // 13px
          height: 0.813rem;
        }
      }
    }
    .modal {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
