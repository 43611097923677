@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");

// Start Global Variables

// Brand Colours
$blue-light: #b3d9ed;
$blue      : #017ACD;
$blue-dark : #015c9a;
$yellow    : #ffce3a;
$orange    : #f26827;
$red       : #b32123;
$green     : #a9b617;
$green-dark: #007a3e;
$purple    : #3c1053;
$white     : #fff;

// Custom colors
$error-red              : #B32123;
$error-red-light        : #fceeee;
$success-green          : #04D684;
$input-border           : #949494;
$input-placeholder-color: #9199A0;
$blue-slightly-darker   : #016bb4;
$background-color       : #f9f9f9;
$foreground-color       : #fff;
$small-heading-color    : #5e5e5e;
$input-text-color       : #363636;
$link-info              : #254E6C;
$link-over-grey         : #026ECF;

// Global variables
$text-default: #333333;
$input-icons : #6E7378;

// End Global Variables

// Html overrides start
html * {
  box-sizing             : border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing : antialiased;
  font-family            : Inter,Arial,sans-serif;
  margin                 : 0;
  padding                : 0;
}

html {
  background-color: #fff;
  color           : $text-default;
  height          : 100%;
  scroll-behavior : smooth;
}

body {
  background-color: $background-color;
  min-height      : 100%;
}

li {
  margin-bottom: 0.5rem;
}

header {
  display        : flex;
  justify-content: center;
  padding        : 50px 40px 40px;

  .header-link:focus {
    background-color: transparent;
  }

  .header-image,
  img {
    max-width: 18.125em; // 276px
  }
}

h1 {
  font-weight: 300;
  font-size  : 2.375rem; // 38px
  color      : $blue;
}

h1:focus,
h1:focus-visible {
  outline: none;
}

a,
a:visited,
button.link {
  color: $blue;
}

.legal a,
.legal a:visited {
  color: $link-over-grey 
}

a:hover,
button.link:hover {
  color: $text-default;
}

a:focus,
button.link:focus {
  background-color: $link-info;
  border-radius: 2px;
  box-shadow: 0 0 0 2px $link-info;
  color: $white;
  outline: none;
  text-decoration: none;
}

.legal a:focus {
  color: $white;
}

strong {
  font-weight: 600;
}

ul {
  margin-bottom: 20px;
  padding-left : 28px;
}

// HTML overrrides end

// Form elements Start
.button,
.file-cta,
.file-name,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select select,
.textarea {
  -moz-appearance   : none;
  -webkit-appearance: none;
  align-items       : center;
  border            : 1px solid transparent;
  border-radius     : 4px;
  box-shadow        : none;
  display           : inline-flex;
  font-size         : 1rem;
  height            : 2.5em;
  justify-content   : flex-start;
  line-height       : 1.5;
  padding-bottom    : calc(.5em - 1px);
  padding-left      : calc(.75em - 1px);
  padding-right     : calc(.75em - 1px);
  padding-top       : calc(.5em - 1px);
  position          : relative;
  vertical-align    : top;
  outline           : none;
}

input::placeholder {
  color: $input-placeholder-color;
}

.input,
.select select,
.textarea {
  background-color: #fff;
  border-color    : $input-border;
  border-radius   : 4px;
  color           : $input-text-color;
  width           : 100%;
  &:focus {
    border-color: $blue;
	  box-shadow: 0 0 0 0.125em $blue;
  }
}

.input.error {
  border-color: $error-red;
  &:focus {
    box-shadow: 0 0 0 0.125em $error-red;
  }
}

.control {
  box-sizing: border-box;
  clear     : both;
  font-size : 1rem;
  position  : relative;
  text-align: inherit;
}

.control.has-icons-right .input:focus~.icon,
.control.has-icons-right .select:focus~.icon {
  color: #4a4a4a;
}

.control.has-icons-right .input.is-small~.icon,
.control.has-icons-right .select.is-small~.icon {
  font-size: 0.75rem;
}

.control.has-icons-right .input.is-medium~.icon,
.control.has-icons-right .select.is-medium~.icon {
  font-size: 1.25rem;
}

.control.has-icons-right .input.is-large~.icon,
.control.has-icons-right .select.is-large~.icon {
  font-size: 1.5rem;
}

.control.has-icons-right .icon {
  height         : 2.5em;
  cursor         : pointer;
  top            : 0;
  width          : 2.5em;
  z-index        : 4;
  position       : absolute;
  display        : flex;
  align-items    : center;
  justify-content: center;

  .far,
  .fas {
    color: $input-icons;
  }
}

.control.has-icons-right .input,
.control.has-icons-right .select select {
  padding-right: 2.5em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.label {
  display      : block;
  font-size    : 1rem;
  font-weight  : 400;
  margin-bottom: 3px;
}

html p.error,
p.help,
p.confirm {
  margin     : 5px 0 0 0;
  color      : $error-red;
  font-size  : .813rem;
  display    : flex;
  align-items: center;

  i.fas,
  i.far {
    margin-right: 5px;
  }
}

html p.confirm {
  color: $green-dark;
}

p.help {
  color: $text-default;
}

.field {
  margin-bottom: 1.875rem; // 30px

  &.term {
    margin-top: 10px;
  }
}

.ww-checkbox {
  & input[type="checkbox"] {
    opacity: 0;

    &+span label {
      cursor: pointer;
    }

    &+span label::after {
      color     : transparent;
      transition: color 0.3s ease;
    }

    &:checked+span label::after {
      color: $blue;
    }

    &.terms:focus{

      &+span {
        border: 1px solid transparent;
        border-radius : 4px;
        border-color: $blue;
        box-shadow: 0 0 0 0.125em $blue;
      }
    
      &.error+span{
        border-color: $red;
        box-shadow: 0 0 0 0.125em $red;
      }
    }
    
  }

  & input[type="checkbox"][disabled] {
    &+span label {
      cursor: not-allowed;
    }

    &+span label::before {
      color: $text-default;
    }

    &:checked+span label::after {
      color: $text-default;
    }
  }

  & span {
    display: inline-block;
    padding: 0.25em 0.3em 0.25em 1.07em;

    & label {
      position    : relative;
      padding-left: 1.75em;
      margin-left : -1em;
      font-size   : 0.875rem;

      &:before {
        font-family: "Font Awesome 5 Free";
        content    : "\f0c8";
        display    : inline-block;
        position   : absolute;
        top        : 0.07em;
        left       : 0;
        font-size  : 1.3em;
        color      : $blue;
      }

      &:after {
        font-family: "Font Awesome 5 Free";
        content    : "\f14a";
        display    : inline-block;
        position   : absolute;
        top        : 0.07em;
        left       : 0;
        font-size  : 1.3em;
        color      : $blue;
      }
    }
  }

  a {
    white-space: nowrap;
  }
}

.button {
  background-color: #fff;
  border-color    : $blue;
  border-width    : 1px;
  color           : $input-text-color;
  cursor          : pointer;
  justify-content : center;
  padding-bottom  : calc(0.5em - 1px);
  padding-left    : 1em;
  padding-right   : 1em;
  padding-top     : calc(0.5em - 1px);
  text-align      : center;
  font-weight     : 600;
  white-space     : nowrap;
  height          : auto;

  &.is-primary {
    background-color: $blue;
    color           : #fff;
  }

  &.is-fullwidth {
    display: flex;
    width  : 100%;
  }

  &.is-wide {
    display: flex;
    width  : 75%;
  }

  &.is-large {
    font-size    : 1.3rem;
    padding-left : 2.2rem;
    padding-right: 2.2rem;
  }

  &:hover {
    background-color: $blue-slightly-darker;
  }

  &:focus {
    background-color: $blue-dark;
    border-color: transparent;
    box-shadow: 0 0 0 2px #fff, 0 0 0 3px #013e67;
    outline: none;
  }

  &:active {
    background-color: $blue-slightly-darker;
    box-shadow: 0 0 0 0px #fff;
    outline: none;
  }
}

.last-form-element {
  margin-bottom: 60px;
}

.help-info-container {
  text-align: center;
  color: $red;

  &:focus, &:focus-visible {
    outline: none;
    
    .help-info {
      outline: 3px solid $error-red;
    }
  }
}

.help-info {
  display      : flex;
  text-align   : center;
  margin-top   : 30px;
  margin-bottom: 1.875rem; // 30px;

  .icon {
    .far {
      padding-right: 8px;
      font-size    : 1.25rem;
      color        : $success-green;
    }
  }

  &.success {

    // align-items: center;
    .icon {
      margin-top: 5px;
    }
  }

  &.success,
  &.error {
    padding: 15px;
    text-align: left;
    margin-top: 0;
  }

  &.error, &.page-error {
    -webkit-border-radius: 4px;
    -moz-border-radius   : 4px;
    border-radius        : 4px;

    border-style: solid;
    border-width: 0 0 0 4px;
    border-color: $error-red;
    background-color: $error-red-light;

    p {
      font-size: 1rem;
      color: $error-red
    }

    &:focus, &:focus-visible {
      outline: 3px solid $error-red;
    }

    .icon {
      padding-right: 8px;

      svg {
        width           : 20px;
        height          : 20px;
        background-color: transparent;
        margin-top      : 5px;
      }

      svg path {
        fill: $error-red;
      }
    }

    .info-text {
      font-size  : 0.875em; // 14px
      font-weight: 600;
    }
  }

  &.no-flex {
    display: block;
  }

  >div {
    background-color: transparent;
  }
}

.help-footer {
  text-align   : center;
  margin-top   : 60px;
  margin-bottom: 0.625rem; // 10px;
}

.confirmation-text {
  text-align   : center;
  font-size    : 25px;
  margin-bottom: 55px;
}

.resend-email-text {
  text-align   : center;
  font-size    : 1.125rem; // 18px
  margin-bottom: 110px;
}

.check-email-help-message {
  width : 50%;
  margin: auto;
}

.email-address {
  white-space: nowrap;
}

.phone-number {
  white-space: nowrap;
  font-weight: 600;
}

.wide-container {
  margin-left : 12%;
  margin-right: 12%;
}

// Hide up/down arrow on number fields

// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

// Firefox
input[type=number] {
  -moz-appearance: textfield;
}

// Form element end

.wrapper {
  display        : flex;
  justify-content: center;

  .footer,
  .container {
    display: flex;

    &.is-enrolment {
      width    : 60%;
      max-width: 61.875rem;

      .form-container {
        width: 26.250rem; // 420px
      }

    }

    &.is-confirmation {
      width    : 60%;
      max-width: 61.875rem;

      .form-container {
        width: 26.250rem; // 420px
      }

    }

    &.is-success,
    &.is-check-email {
      width    : 60%;
      max-width: 61.875rem;
    }

    &.is-maintenance {
      width: 1000px;
    }

    &.is-forgot-password {
      width: 400px;
    }
  }

  .footer {
    justify-content: space-between;
    margin-top     : 1.2rem;
    margin-bottom  : 3rem;

    &.is-forgot-password {
      font-size     : 0.8em;
      flex-direction: column;

      >div {
        text-align: center;
      }

      .copyright,
      .legal {
        margin-bottom: 10px;
      }
    }
  }

  .container {
    border          : 1px solid #ddd;
    padding-top: 60px;
    padding-bottom: 40px;
    padding-right: 40px;
    padding-left: 40px;
    justify-content : center;
    background-color: $foreground-color;

    &.is-forgot-password {
      padding: 55px 40px 10px 40px;
    }

    &.is-maintenance {
      padding: 100px;
    }

    &.is-success {
      padding: 60px 40px 90px;
    }
  }

  .success-check {
    margin-bottom: 3px;
  }

  h1 {
    text-align   : center;
    margin-bottom: 10px;
  }

  .extra-margin {
    margin-bottom: 60px;
  }

  .is-success {
    text-align   : center;
    margin-bottom: 10px;

    &.confirmation-text {
      margin-bottom: 40px;
    }
  }

  .container.is-forgot-password h1 {
    color      : $small-heading-color;
    font-weight: 600;
    font-size  : 1.5em;
  }

  .container.is-enrolment .submit {
    margin-top: 40px;
  }

  .container.is-forgot-password .submit {
    margin-top: 50px;
  }

  .container.is-forgot-password.is-forgot-password-success .submit {
    margin-top: 30px;
  }

  .container.is-forgot-password-success .help-footer {
    margin-top: 60px;
  }
}

.terms h1 {
  text-align: left;
}

.ww-center-content {
  text-align: center;
}

.timer-container {
  margin-bottom: 1.875rem;
  text-align   : center;
  width        : 100%;
}

.countdown-timer {
  text-align : center;
  color      : #d42629;
  font-size  : 20px;
  line-height: 30px;
}

.is-maintenance {
  .message {
    text-align: center;
    font-size : 1.5em;
  }

  .phone-number {
    font-weight: normal;
  }
}

.is-confirmation {
  .header-icon {
    text-align   : center;
    margin-bottom: 20px;
  }

  .message {
    text-align   : center;
    font-size    : 1.5em;
    margin-bottom: 2em;
  }
}

.icon .is-error {
  fill  : $error-red;
  width : 48px;
  height: 48px;
}

.check-email-container {
  position: relative;
}

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
// General CSS spinner HTML should be constructed like so:
// <div class="spinner">
//   <div class="bounce1"></div>
//   <div class="bounce2"></div>
//   <div class="bounce3"></div>
// </div>
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

.spinner {
  margin       : 30px auto 0;
  margin-bottom: 20px;
  width        : 70px;
  text-align   : center;
}

.spinner>div {
  width            : 18px;
  height           : 18px;
  background-color : $blue;
  border-radius    : 100%;
  display          : inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation        : sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay        : -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay        : -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform        : scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform        : scale(1);
  }
}

.loading {
  .modal {
    width: 700px;
  }

  h1 {
    font-weight  : 400;
    margin-bottom: 10px;
  }
}

a.button {
  text-decoration: none;
}

button.link {
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  font-size: inherit;
}


@media only screen and (max-width: 80rem) {
  /*1280px*/

  .confirmation-text {
    font-size: 22px;
  }
}

@media only screen and (max-width: 62.5em) {

  /*1000px*/
  .wrapper .container {
    &.is-maintenance {
      width: 90%;
    }

    &.is-success {
      width: 75%;
    }
  }

  .wrapper .footer {
    flex-direction: column;

    >div {
      text-align: center;
    }

    .copyright,
    .legal {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 56.25em) {

  /*900px*/
  .wrapper .container {

    &.is-enrolment,
    &.is-check-email,
    &.is-success {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 37.5em) {

  /*600px*/
  .wrapper .container.is-maintenance {
    padding: 20px;
  }

  .confirmation-text {
    font-size: 20px;
  }

  .check-email-help-message {
    width: 90%;
  }

  .wrapper .container.is-forgot-password {
    width       : 100%;
    border-width: 1px 0 0 0;

    .form-container {
      width        : 400px;
      padding-left : 40px;
      padding-right: 40px;
    }
  }
}

@media only screen and (max-width: 34.375em) {

  /*550px*/
  .last-form-element {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 31.25em) {

  /*500px*/
  .wrapper .container {
    padding: 40px 20px;

    &.is-enrolment,
    &.is-confirmation,
    &.is-check-email,
    &.is-success {
      width: 95%;
    }
  }

  .check-email-help-message {
    width: 95%;
  }

  .confirmation-text {
    font-size: 20px;
  }

  .field.term {
    margin-top: 30px;
  }

  .submit {
    margin-top: 40px;
  }

  .ww-checkbox span label {
    margin-left: 0;
  }

  .ww-checkbox span {
    padding: 0;
  }
}

@media only screen and (max-width: 25em) {

  /*400px*/
  header {
    padding: 25px 10px;

    a,
    button.link {
      text-align: center;
    }

    img {
      max-width: 90%;
      padding  : 0 10px;
    }
  }

  h1 {
    font-size: 1.9em;
  }

  .confirmation-text {
    font-size: 1em;
  }

  .help-footer {
    font-size: 0.8em;
  }

}

.bolded {
  font-weight: 700;
}

.success-check {
  text-align: center;

  .icon {
    .far {
      font-size: 2.75rem;
      color    : $success-green;
    }
  }
}

.signin{
  text-align: center;
  margin-top: 40px;
}

.logo-img {
  width: 600px;
}

// Custom modal styles
.email-exists .modal-head {
  justify-content: flex-end;
}

// Message box
.msg-box {
  background-color: $background-color;
  margin-bottom: 2rem;
}

.message-body {
  border-color: $blue;
  border-style: solid;
  border-width: 0 0 0 4px;
  padding: 1.25em 1.5em;
}

// Helper classes
.is-hidden {
  display: none;
}

.no-wrap {
  white-space: nowrap;
}

.grecaptcha-badge { 
  visibility: hidden; 
}

.recaptcha-help {
  font-size     : 0.8em;
  .help-footer {
    margin-top   : 2.5rem;
  }
}

// Screen reader only - visibly hides the element from the page
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.timer-message {
  margin-bottom: 1.5rem;
}