$green-success: #0b9c64;

.toast-container {
  position: fixed;
  top: 10px;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
  animation: slideDown 0.5s, slideUp 0.5s 5s forwards;
  -webkit-animation: slideDown  0.5s, slideUp 0.5s 5s forwards;
  -moz-animation: slideDown 0.5s, slideUp 0.5s 5s forwards;
  -o-animation: slideDown 0.5s, slideUp 0.5s 5s forwards;
  -ms-animation: slideDown 0.5s, slideUp 0.5s 5s forwards;

  @media only screen and (max-width: 56.25em) { /*900px*/
    width: 90%;
  }

  @media only screen and (max-width: 31.25em) { /*500px*/
    width: 95%;
  }
}

@keyframes slideDown {
  from{top: -90px;}
  to{top:10px;}
}

@-moz-keyframes slideDown {
  from{top: -90px;}
  to{top:10px;}
}

@-webkit-keyframes slideDown {
  from{top: -90px;}
  to{top:10px;}
}

@-o-keyframes slideDown {
  from{top: -90px;}
  to{top:10px;}
}

@-ms-keyframes slideDown {
  from{top: -90px;}
  to{top:10px;}
}

@keyframes slideUp {
  to{top:-90px;}
}

@-moz-keyframes slideUp {
  to{top:-90px;}
}

@-webkit-keyframes slideUp {
  to{top:-90px;}
}

@-o-keyframes slideUp {
  to{top:-90px;}
}

@-ms-keyframes slideUp {
  to{top:-90px;}
}

.toast-notification {
  padding: 1rem 1rem 1rem 0.875rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  p {
    display: flex;
    align-items: center;
    color: $white;
    font-size: 1.4rem;
    text-align: left;
    margin-right: 50px;
    white-space: nowrap;
    margin-bottom: 2px;
  }

  .toast-close  {
    justify-self: flex-end;
    margin-bottom: 2px;

    button {
      color: $white;
      font-size: 2.2rem;
    }

    button:hover {
      color: $white;
    }
  }

  .toast-icon {
    margin-right: 13px;
    font-size: 2.2rem;
    margin-bottom: 2px;
  }

  &.is-success {
    background-color: $green-success;
  }

  @media only screen and (max-width: 56.25em) { /*900px*/
    p {
      margin-right: 0;
    }
  }
  
  @media only screen and (max-width: 37.5em) { /*600px*/
    .toast-icon {
      font-size: 2rem;
    }
    p {
      font-size: 1.2rem;
    }
    .toast-close button {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 31.25em) { /*500px*/
    .toast-icon {
      font-size: 1.75rem;
    }
    p {
      font-size: 0.875rem;
    }
    .toast-close button {
      font-size: 1.75rem;
    }
  }

  @media only screen and (max-width: 21.875em) { /*350px*/
    .toast-icon {
      font-size: 1.25rem;
    }
    .toast-close button {
      font-size: 1.25rem;
    }
  }
}
